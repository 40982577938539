import create from 'zustand';

//Import slices
import createAppSlice, { AppSlice } from 'store/createAppSlice';

export type MyState = AppSlice;

const useStore = create<MyState>((set, get) => ({
  ...createAppSlice(set, get)
  //   ...createFishSlice(set, get)
}));

export default useStore;
