import { GetState, SetState } from 'zustand';
import { MyState } from './useStore';

export interface AppSlice {
  isDrawerOpen: boolean;
  toggleDrawer: () => void;
}

const createAppSlice = (set: SetState<MyState>, get: GetState<MyState>) => ({
  isDrawerOpen: false,
  toggleDrawer: () => {
    set((prev) => ({ isDrawerOpen: !prev.isDrawerOpen }));
  }
});

export default createAppSlice;
