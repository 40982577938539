import React from 'react';
import useStore from 'store/useStore';

// 1. Import pages here
// 2. Setup public and private routes
// 3. Auth logic - Redirect correctly depending on auth

const App = () => {
  // CODE BELOW: EXAMPLE OF ZUSTAND
  const isDrawerOpen = useStore((state) => state.isDrawerOpen);
  const toggleDrawer = useStore((state) => state.toggleDrawer);
  // Implement auth check here
  return (
    <div>
      <button onClick={toggleDrawer}>TOGGLE</button>
      <p>{isDrawerOpen ? 'open' : 'closed'}</p>
    </div>
  );
};

export default App;
